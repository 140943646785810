<template>
  <div>
    <svg
      class="compass"
      viewBox="-20 0 279.775  240.399"
      width="239.775"
      height="240.399"
      version="1.0"
      style="font-size: 14.6px; font-family: Georgia; fill: #ffdd55; stroke-width: 4"
      id="svg166"
      sodipodi:docname="compass-rose-touch16.svg"
      inkscape:version="1.1 (c4e8f9e, 2021-05-24)"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
    >
      <metadata id="metadata170">
        <rdf:RDF>
          <cc:Work rdf:about="">
            <dc:format>image/svg+xml</dc:format>
            <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          </cc:Work>
        </rdf:RDF>
      </metadata>
      <sodipodi:namedview
        pagecolor="#ffffff"
        bordercolor="#666666"
        borderopacity="1"
        objecttolerance="10"
        gridtolerance="10"
        guidetolerance="10"
        inkscape:pageopacity="0"
        inkscape:pageshadow="2"
        inkscape:window-width="1627"
        inkscape:window-height="1198"
        id="namedview168"
        showgrid="false"
        inkscape:current-layer="svg166"
        inkscape:pagecheckerboard="0"
        inkscape:zoom="3.6314627"
        inkscape:cx="119.92413"
        inkscape:cy="120.06181"
        inkscape:window-x="457"
        inkscape:window-y="1105"
        inkscape:window-maximized="0"
      />
      <defs id="defs44">
        <linearGradient id="id3">
          <stop offset="0" style="stop-color: #ffe165; stop-opacity: 1" id="stop2" />
          <stop offset="0.327" style="stop-color: #f60; stop-opacity: 1" id="stop4" />
          <stop offset="1" style="stop-color: #bf0000; stop-opacity: 1" id="stop6" />
        </linearGradient>
        <linearGradient id="id2">
          <stop offset="0" style="stop-color: #806600; stop-opacity: 1" id="stop9" />
          <stop offset="0.218" style="stop-color: #693603; stop-opacity: 1" id="stop11" />
          <stop offset="1" style="stop-color: #530707; stop-opacity: 1" id="stop13" />
        </linearGradient>
        <linearGradient id="linearGradient20">
          <stop offset="0" style="stop-color: #806600; stop-opacity: 1" id="stop16" />
          <stop offset="1" style="stop-color: #806600; stop-opacity: 0" id="stop18" />
        </linearGradient>
        <linearGradient id="id1">
          <stop offset="0" style="stop-color: #ffff00; stop-opacity: 1" id="stop22" />
          <stop offset="0.636" style="stop-color: #4a85d3; stop-opacity: 0.36" id="stop24" />
          <stop offset="1" style="stop-color: #63b6d1; stop-opacity: 0" id="stop26" />
        </linearGradient>
        <path
          style="fill: none; stroke-width: 1"
          d="M 0,0 Z"
          id="path29"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: none; stroke-width: 1"
          d="m 40,350 c 0,80 10,140 40,190 10,30 30,70 60,100 30,20 60,50 100,60 40,20 90,30 150,30 110,0 190,-30 250,-100 C 710,560 740,470 740,360 740,240 710,150 640,90 580,20 500,-10 390,-10 290,-10 200,20 140,90 70,150 40,240 40,350 Z m 150,10 c 0,-80 20,-140 60,-190 40,-40 80,-60 140,-60 60,0 110,20 140,60 40,50 60,110 60,190 0,80 -20,140 -60,180 -30,40 -80,60 -140,60 -60,0 -110,-20 -140,-60 -40,-40 -60,-100 -60,-180 z"
          id="path31"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: none; stroke-width: 1"
          d="M 70,0 V 720 H 210 L 510,240 V 720 H 640 V 0 H 500 L 210,470 V 0 Z"
          id="path33"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: none; stroke-width: 1"
          d="M 170,0 0,720 H 150 L 260,220 390,720 H 560 L 690,220 800,720 H 940 L 770,0 H 620 L 470,540 330,0 Z"
          id="path35"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: none; stroke-width: 1"
          d="m 40,230 140,20 c 10,-50 20,-90 50,-110 20,-20 60,-30 100,-30 50,0 80,10 110,30 20,20 30,40 30,70 0,20 0,30 -10,40 -10,10 -30,20 -50,30 -20,10 -60,20 -120,30 -80,20 -130,50 -160,70 -50,40 -70,90 -70,150 0,30 10,70 30,100 20,30 50,60 90,70 40,20 90,30 140,30 90,0 160,-20 210,-60 40,-40 70,-90 70,-160 L 450,500 c 0,40 -20,70 -40,80 -20,20 -50,30 -90,30 -40,0 -70,-10 -100,-30 -10,-10 -20,-20 -20,-40 0,-20 10,-30 20,-50 20,-10 60,-30 130,-40 70,-20 130,-40 160,-50 30,-20 60,-50 80,-80 20,-30 30,-70 30,-110 C 620,170 610,130 580,90 560,60 530,30 490,10 450,0 390,-10 330,-10 240,-10 170,10 120,50 70,90 50,150 40,230 Z"
          id="path37"
          inkscape:connector-curvature="0"
        />
        <radialGradient
          id="id4"
          r="771.033"
          fx="1410.8"
          fy="1410.8"
          cx="1410.8"
          cy="1410.8"
          gradientTransform="matrix(0.126158,0,0,0.126158,-60.6503,-60.6422)"
          xlink:href="#id1"
          gradientUnits="userSpaceOnUse"
        />
        <linearGradient
          xlink:href="#id2"
          x1="1460.8"
          y1="1418.5"
          x2="1460.8"
          y2="352.7"
          gradientUnits="userSpaceOnUse"
          id="linearGradient40"
        />
        <linearGradient
          xlink:href="#id3"
          x1="1417.5"
          y1="1416"
          x2="1417.5"
          y2="353"
          gradientUnits="userSpaceOnUse"
          id="linearGradient42"
        />
      </defs>
      <circle
        cx="117.3"
        cy="117.316"
        r="97.1652"
        style="fill: url(#id4); stroke-width: 0.13"
        id="circle46"
      />
      <g transform="matrix(0.093097,0,0,0.093097,-7.66176,-14.0242)" id="g66">
        <path
          style="stroke: #1f1a17; stroke-linejoin: round"
          d="m 1102,654 361,656 -45,108 -108,-45 z"
          id="path48"
          inkscape:connector-curvature="0"
        />
        <path
          style="stroke: #1f1a17; stroke-linejoin: round"
          d="m 1734,2182 -208,-719 -108,-45 -45,108 z"
          id="path50"
          inkscape:connector-curvature="0"
        />
        <path
          style="stroke: #1f1a17; stroke-linejoin: round"
          d="m 654,1734 656,-361 108,45 -45,108 z"
          id="path52"
          inkscape:connector-curvature="0"
        />
        <path
          style="stroke: #1f1a17; stroke-linejoin: round"
          d="m 2182,1102 -719,208 -45,108 108,45 z"
          id="path54"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: #aa8800; stroke: #1f1a17; stroke-linejoin: round"
          d="m 1418,1418 316,764 -208,-719 z"
          id="path56"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: #aa8800; stroke: #1f1a17; stroke-linejoin: round"
          d="m 1418,1418 764,-316 -656,361 z"
          id="path58"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: none; stroke: #1f1a17; stroke-linejoin: round"
          d="m 1418,1418 120,50"
          id="path60"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: #aa8800; stroke: #1f1a17; stroke-linejoin: round"
          d="m 1418,1418 -316,-764 361,656 z"
          id="path62"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: #aa8800; stroke: #1f1a17; stroke-linejoin: round"
          d="m 1418,1418 -764,316 719,-208 z"
          id="path64"
          inkscape:connector-curvature="0"
        />
      </g>
      <g transform="matrix(0.093097,0,0,0.093097,-7.66176,-14.0242)" id="g86">
        <path
          style="stroke: #1f1a17; stroke-linejoin: round"
          d="m 654,1102 719,208 45,108 -108,45 z"
          id="path68"
          inkscape:connector-curvature="0"
        />
        <path
          style="stroke: #1f1a17; stroke-linejoin: round"
          d="m 2182,1734 -656,-361 -108,45 45,108 z"
          id="path70"
          inkscape:connector-curvature="0"
        />
        <path
          style="stroke: #1f1a17; stroke-linejoin: round"
          d="m 1102,2182 208,-719 108,-45 45,108 z"
          id="path72"
          inkscape:connector-curvature="0"
        />
        <path
          style="stroke: #1f1a17; stroke-linejoin: round"
          d="m 1734,654 -361,656 45,108 108,-45 z"
          id="path74"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: #aa8800; stroke: #1f1a17; stroke-linejoin: round"
          d="m 1418,1418 764,316 -656,-361 z"
          id="path76"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: #aa8800; stroke: #1f1a17; stroke-linejoin: round"
          d="m 1418,1418 316,-764 -208,719 z"
          id="path78"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: none; stroke: #1f1a17; stroke-linejoin: round"
          d="m 1418,1418 120,-50"
          id="path80"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: #aa8800; stroke: #1f1a17; stroke-linejoin: round"
          d="m 1418,1418 -764,-316 719,208 z"
          id="path82"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: #aa8800; stroke: #1f1a17; stroke-linejoin: round"
          d="m 1418,1418 -316,764 361,-656 z"
          id="path84"
          inkscape:connector-curvature="0"
        />
      </g>
      <g transform="matrix(0.093097,0,0,0.093097,-7.66176,-14.0242)" id="g104">
        <path
          style="stroke: #1f1a17; stroke-linejoin: round"
          d="m 2086,750 -534,668 h -134 v -134 z"
          id="path88"
          inkscape:connector-curvature="0"
        />
        <path
          style="stroke: #1f1a17; stroke-linejoin: round"
          d="m 750,2086 668,-534 v -134 h -134 z"
          id="path90"
          inkscape:connector-curvature="0"
        />
        <path
          style="stroke: #1f1a17; stroke-linejoin: round"
          d="m 750,750 668,534 v 134 h -134 z"
          id="path92"
          inkscape:connector-curvature="0"
        />
        <path
          style="stroke: #1f1a17; stroke-linejoin: round"
          d="m 2086,2086 -534,-668 h -134 v 134 z"
          id="path94"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: #806600; stroke: #1f1a17; stroke-linejoin: round"
          d="m 1418,1418 -668,668 668,-534 z"
          id="path96"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: #aa8800; stroke: #1f1a17; stroke-linejoin: round"
          d="m 1418,1418 668,668 -668,-534 z"
          id="path98"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: #806600; stroke: #1f1a17; stroke-linejoin: round"
          d="m 1418,1418 668,-668 -534,668 z"
          id="path100"
          inkscape:connector-curvature="0"
        />
        <path
          style="fill: #aa8800; stroke: #1f1a17; stroke-linejoin: round"
          d="M 1418,1418 750,750 1284,1418 Z"
          id="path102"
          inkscape:connector-curvature="0"
        />
      </g>
      <path
        style="fill: none; stroke: #1f1a17; stroke-width: 0.37; stroke-linejoin: round"
        d="m 123.1,118.316 v 13.8"
        id="path106"
        inkscape:connector-curvature="0"
      />
      <path
        style="fill: #fafbfb; stroke: #1f1a17; stroke-width: 0.37; stroke-linejoin: round"
        d="m 124.3,216.916 9.9,-89.1 -9.9,-9.9 -9.9,9.9 z"
        id="path108"
        inkscape:connector-curvature="0"
      />
      <path
        style="stroke: #1f1a17; stroke-width: 0.37; stroke-linejoin: round"
        d="m 25.3996,118.016 89.1004,-9.9 9.9,9.9 -9.9,9.9 z"
        id="path110"
        inkscape:connector-curvature="0"
      />
      <path
        style="stroke: #1f1a17; stroke-width: 0.37; stroke-linejoin: round"
        d="m 223.3,118.016 -89.1,-9.9 -9.9,9.9 9.9,9.9 z"
        id="path112"
        inkscape:connector-curvature="0"
      />
      <path
        style="fill: #969594; stroke: #1f1a17; stroke-width: 0.37; stroke-linejoin: round"
        d="m 124.3,118.016 v 99 l 9.9,-89.1 z"
        id="path114"
        inkscape:connector-curvature="0"
      />
      <path
        style="fill: #806600; stroke: #1f1a17; stroke-width: 0.37; stroke-linejoin: round"
        d="m 124.3,118.016 h 99 l -89.1,9.9 z"
        id="path116"
        inkscape:connector-curvature="0"
      />
      <path
        style="fill: none; stroke: #1f1a17; stroke-width: 0.37; stroke-linejoin: round"
        d="m 124.3,118.016 11,11"
        id="path118"
        inkscape:connector-curvature="0"
      />
      <path
        style="fill: #806600; stroke: #1f1a17; stroke-width: 0.37; stroke-linejoin: round"
        d="M 124.3,118.016 H 25.3996 l 89.1004,9.9 z"
        id="path120"
        inkscape:connector-curvature="0"
      />

      <g class="point-label">
        <circle cx="125.954" cy="10.476601" r="18" stroke-width="1" />
        <text
          x="133.955"
          y="18.476601"
          style="
            font-style: italic;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 26.6667px;
            font-family: Georgia;
            -inkscape-font-specification: 'Georgia, Italic';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-feature-settings: normal;
            text-align: end;
            writing-mode: lr-tb;
            text-anchor: end;
            fill: #1f1a17;
            stroke-width: 0.52;
          "
          id="point_N"
        >
          <desc id="desc390">0</desc>
          <title id="title388">N</title>
          N
        </text>
      </g>

      <g class="point-label">
        <circle cx="233.14999" cy="119.593" r="18" stroke-width="1" />
        <text
          x="239.14999"
          y="127.593"
          style="
            font-style: italic;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 26.6667px;
            font-family: Georgia;
            -inkscape-font-specification: 'Georgia, Italic';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-feature-settings: normal;
            text-align: end;
            writing-mode: lr-tb;
            text-anchor: end;
            fill: #1f1a17;
            stroke-width: 0.52;
          "
          id="point_E"
        >
          <desc id="desc735">90</desc>
          <title id="title733">E</title>
          E
        </text>
      </g>

      <g class="point-label">
        <circle cx="124.011" cy="232.021" r="18" stroke-width="1" />
        <text
          x="130.011"
          y="240.021"
          style="
            font-style: italic;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 26.6667px;
            font-family: Georgia;
            -inkscape-font-specification: 'Georgia, Italic';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-feature-settings: normal;
            text-align: end;
            writing-mode: lr-tb;
            text-anchor: end;
            fill: #1f1a17;
            stroke-width: 0.52;
          "
          id="point_S"
        >
          <desc id="desc893">180</desc>
          <title id="title891">S</title>
          S
        </text>
      </g>

      <g class="point-label">
        <circle cx="13.6719" cy="119.593" r="18" stroke-width="1" />
        <text
          x="23.6719"
          y="127.593"
          style="
            font-style: italic;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 26.6667px;
            font-family: Georgia;
            -inkscape-font-specification: 'Georgia, Italic';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-feature-settings: normal;
            text-align: end;
            writing-mode: lr-tb;
            text-anchor: end;
            fill: #1f1a17;
            stroke-width: 0.52;
          "
          id="point_W"
        >
          <desc id="desc282">-90</desc>
          <title id="title176">W</title>
          W
        </text>
      </g>

      <g class="point-label">
        <circle cx="201.799" cy="182.673" r="18" stroke-width="1" />
        <text
          x="210.799"
          y="189.673"
          style="
            font-style: italic;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 18.6667px;
            font-family: Georgia;
            -inkscape-font-specification: 'Georgia, Italic';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-feature-settings: normal;
            text-align: end;
            writing-mode: lr-tb;
            text-anchor: end;
            fill: #1f1a17;
            stroke-width: 0.52;
          "
          id="point_SE"
        >
          <desc id="desc743">135</desc>
          <title id="title741">SE</title>
          SE
        </text>
      </g>

      <g class="point-label">
        <circle cx="46.371899" cy="183.117" r="18" stroke-width="1" />
        <text
          x="58.371899"
          y="190.117"
          style="
            font-style: italic;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 18.6667px;
            font-family: Georgia;
            -inkscape-font-specification: 'Georgia, Italic';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-feature-settings: normal;
            text-align: end;
            writing-mode: lr-tb;
            text-anchor: end;
            fill: #1f1a17;
            stroke-width: 0.52;
          "
          id="point_SW"
        >
          <desc id="desc3921">-135</desc>
          <title id="title3919">SW</title>
          SW
        </text>
      </g>

      <g class="point-label">
        <circle cx="203.68201" cy="53.165798" r="18" stroke-width="1" />
        <text
          x="213.68201"
          y="60.165798"
          style="
            font-style: italic;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 18.6667px;
            font-family: Georgia;
            -inkscape-font-specification: 'Georgia, Italic';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-feature-settings: normal;
            text-align: end;
            writing-mode: lr-tb;
            text-anchor: end;
            fill: #1f1a17;
            stroke-width: 0.52;
          "
          id="point_NE"
        >
          <desc id="desc727">45</desc>
          <title id="title725">NE</title>
          NE
        </text>
      </g>

      <g class="point-label">
        <circle cx="47.3382" cy="50.1717" r="18" stroke-width="1" />
        <text
          x="61.3382"
          y="57.1717"
          style="
            font-style: italic;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 18.6667px;
            font-family: Georgia;
            -inkscape-font-specification: 'Georgia, Italic';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-feature-settings: normal;
            text-align: end;
            writing-mode: lr-tb;
            text-anchor: end;
            fill: #1f1a17;
            stroke-width: 0.52;
          "
          id="point_NW"
        >
          <title id="title382">NW</title>
          <desc id="desc380">-45</desc>
          NW
        </text>
      </g>

      <g class="point-label">
        <circle cx="90.871" cy="37.944" r="16" stroke-width="1" />
        <text
          x="104.871"
          y="42.944"
          style="
            font-style: italic;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 12px;
            font-family: Georgia;
            -inkscape-font-specification: 'Georgia, Italic';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-feature-settings: normal;
            text-align: end;
            writing-mode: lr-tb;
            text-anchor: end;
            fill: #0e232e;
            stroke-width: 0.52;
          "
          id="point_NNW"
        >
          <desc id="desc386">-22.5</desc>
          <title id="title384">NNW</title>
          NNW
        </text>
      </g>

      <g class="point-label">
        <circle cx="159.45" cy="37.944" r="15" stroke-width="1" />
        <text
          x="171.45"
          y="42.944"
          style="
            font-style: italic;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 12px;
            font-family: Georgia;
            -inkscape-font-specification: 'Georgia, Italic';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-feature-settings: normal;
            text-align: end;
            writing-mode: lr-tb;
            text-anchor: end;
            fill: #0e232e;
            stroke-width: 0.52;
          "
          id="point_NNE"
        >
          <desc id="desc394">22.5</desc>
          <title id="title392">NNE</title>
          NNE
        </text>
      </g>

      <g class="point-label">
        <circle cx="212.593" cy="87.016197" r="15" stroke-width="1" />
        <text
          x="223.593"
          y="91.016197"
          style="
            font-style: italic;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 12px;
            font-family: Georgia;
            -inkscape-font-specification: 'Georgia, Italic';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-feature-settings: normal;
            text-align: end;
            writing-mode: lr-tb;
            text-anchor: end;
            fill: #0e232e;
            stroke-width: 0.52;
          "
          id="point_ENE"
        >
          <desc id="desc731">67.5</desc>
          <title id="title729">ENE</title>
          ENE
        </text>
      </g>

      <g class="point-label">
        <circle cx="210.327" cy="149.938" r="15" stroke-width="1" />
        <text
          x="220.327"
          y="154.938"
          style="
            font-style: italic;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 12px;
            font-family: Georgia;
            -inkscape-font-specification: 'Georgia, Italic';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-feature-settings: normal;
            text-align: end;
            writing-mode: lr-tb;
            text-anchor: end;
            fill: #0e232e;
            stroke-width: 0.52;
          "
          id="point_ESE"
        >
          <desc id="desc739">112.5</desc>
          <title id="title737">ESE</title>
          ESE
        </text>
      </g>

      <g class="point-label">
        <circle cx="158.983" cy="196.64999" r="15" stroke-width="1" />
        <text
          x="167.983"
          y="200.64999"
          style="
            font-style: italic;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 12px;
            font-family: Georgia;
            -inkscape-font-specification: 'Georgia, Italic';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-feature-settings: normal;
            text-align: end;
            writing-mode: lr-tb;
            text-anchor: end;
            fill: #0e232e;
            stroke-width: 0.52;
          "
          id="point_SSE"
        >
          <desc id="desc747">157.5</desc>
          <title id="title745">SSE</title>
          SSE
        </text>
      </g>

      <g class="point-label">
        <circle cx="90.516" cy="195.64999" r="15" stroke-width="1" />
        <text
          x="102.516"
          y="200.64999"
          style="
            font-style: italic;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 12px;
            font-family: Georgia;
            -inkscape-font-specification: 'Georgia, Italic';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-feature-settings: normal;
            text-align: end;
            writing-mode: lr-tb;
            text-anchor: end;
            fill: #0e232e;
            stroke-width: 0.52;
          "
          id="point_SSW"
        >
          <desc id="desc1587">-157.5</desc>
          <title id="title1585">SSW</title>
          SSW
        </text>
      </g>

      <g class="point-label">
        <circle cx="33.327301" cy="148.938" r="15" stroke-width="1" />
        <text
          x="47.327301"
          y="154.938"
          style="
            font-style: italic;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 12px;
            font-family: Georgia;
            -inkscape-font-specification: 'Georgia, Italic';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-feature-settings: normal;
            text-align: end;
            writing-mode: lr-tb;
            text-anchor: end;
            fill: #0e232e;
            stroke-width: 0.52;
          "
          id="point_WSW"
        >
          <desc id="desc5827">-112.5</desc>
          <title id="title5825">WSW</title>
          WSW
        </text>
      </g>

      <g class="point-label">
        <circle cx="35.155102" cy="85.016197" r="15" stroke-width="1" />
        <text
          x="50.155102"
          y="91.016197"
          style="
            font-style: italic;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 12px;
            font-family: Georgia;
            -inkscape-font-specification: 'Georgia, Italic';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-feature-settings: normal;
            text-align: end;
            writing-mode: lr-tb;
            text-anchor: end;
            fill: #0e232e;
            stroke-width: 0.52;
          "
          id="point_WNW"
        >
          <title id="title180">WNW</title>
          <desc id="desc178">-67.5</desc>
          WNW
        </text>
      </g>
      <g transform="matrix(0.093097,0,0,0.093097,-7.66176,-14.0242)" id="g158">
        <path
          style="fill: #786721; stroke: #1f1a17; stroke-linejoin: round"
          d="m 1418,2481 -106,-957 106,-106 106,106 z"
          id="path154"
          inkscape:connector-curvature="0"
        />
        <path
          style="stroke: #1f1a17; stroke-linejoin: round"
          d="M 1418,1418 V 2481 L 1312,1524 Z"
          id="path156"
          inkscape:connector-curvature="0"
        />
      </g>
      <g transform="matrix(-0.093097,0,0,-0.093097,256.358,250.536)" id="g164">
        <path
          style="fill: #786721; stroke: #1f1a17; stroke-linejoin: round"
          d="m 1418,2481 -106,-957 106,-106 106,106 z"
          id="path160"
          inkscape:connector-curvature="0"
        />
        <path
          style="stroke: #1f1a17; stroke-linejoin: round"
          d="M 1418,1418 V 2481 L 1312,1524 Z"
          id="path162"
          inkscape:connector-curvature="0"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import Orientation from '../services/orientation';
const orientation = new Orientation();
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      point: ''
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val && orientation.isValidPoint(val)) {
          this.onPointSelect(this.value);
        }
      }
    }
  },

  mounted() {
    orientation.points.forEach(point => {
      this.addAttributes(point);
      this.addEventListener(point);
      // this.addCircle(point);
    });
    this.onPointSelect(this.value);
  },

  methods: {
    pointElement(point) {
      return document.getElementById(`point_${point}`);
    },
    addAttributes(point) {
      const elem = this.pointElement(point);
      elem.setAttribute('text-anchor', 'middle');
    },
    addEventListener(point) {
      const elem = this.pointElement(point);
      elem.addEventListener('click', () => {
        this.onPointSelect(point);
      });
    },

    // addCircle(point) {
    //   console.log(point);
    //   const elem = d3.select(".point_N");
    //   console.log(elem);

    //   // const e = this.pointElement(point);
    //   // const x = e.getAttribute("x");
    //   // const y = e.getAttribute("y");
    //   // const c = document.createElement("circle");

    //   // c.setAttribute("cx", x);
    //   // c.setAttribute("cy", y);
    //   // c.setAttribute("r", 20);
    //   // c.setAttribute("stroke", "red");
    //   // console.log(c);

    //   // const parent = e.parentNode;
    //   // parent.insertBefore(c, e);
    //   // <circle
    //   //   cx="126.955"
    //   //   cy="18.476601"
    //   //   r="20"
    //   //   stroke="red"
    //   //   stroke-width="3"
    //   //   fill="none"
    //   // />
    // },

    removeActive(p) {
      if (!p) {
        return;
      }
      const elem = this.pointElement(p).parentElement;
      elem.classList.remove('point-label--active');
    },
    addActive(p) {
      if (!p) {
        return;
      }
      const elem = this.pointElement(p).parentElement;
      elem.classList.add('point-label--active');
    },

    onPointSelect(point) {
      const prevPoint = this.point;

      this.removeActive(prevPoint);
      this.addActive(point);

      this.point = point;
      this.$emit('input', point);
    }
  }
};
</script>

<style scoped lang="scss">
@import '../design/_colors.scss';

.point-label {
  cursor: pointer;
  circle {
    fill: transparent;
    stroke: grey;
    stroke-opacity: 0.03;
    stroke-dasharray: '10.71, 5';
  }
  text {
    fill: black;
  }

  &:hover {
    circle {
      // fill: $color-primary;
      stroke: $color-primary;
      stroke-opacity: 1 !important;
    }
    text {
      // fill: white !important;
      fill: $color-primary !important;
    }
  }
}

.point-label--active {
  circle {
    // fill: $color-primary;
    stroke: $color-primary;
    stroke-opacity: 1 !important;
  }
  text {
    // fill: white !important;
    fill: $color-primary !important;
  }
}
</style>
